const activeClass = "posts-table-of-contents-component__link--active"

document.addEventListener("DOMContentLoaded", () => {
  // Get all links in the table of contents
  const tocLinks = document.querySelectorAll(
    ".posts-table-of-contents-component__link",
  )

  if (tocLinks.length === 0) return

  const sections = [...tocLinks].map((anchor) => {
    const href = anchor.href
    const hashIndex = href.lastIndexOf("#")
    const id = href.slice(hashIndex)

    return document.querySelector(id)
  })

  let currentSection

  // Function to update active state
  const updateActiveSection = () => {
    const scrollPosition = window.scrollY

    let newSection

    // Find the current section based on scroll position
    sections.forEach((section) => {
      const sectionTop = section.offsetTop - window.innerHeight / 2 // Offset for better UX

      if (scrollPosition >= sectionTop) {
        newSection = section
      }
    })

    // Only update if the current section has changed
    if (currentSection !== newSection) {
      // Remove active class from all links
      tocLinks.forEach((link) => {
        link.classList.remove(activeClass)
      })

      if (newSection) {
        // Add active class to current section's link
        const activeLink = document.querySelector(
          `.posts-table-of-contents-component__link[href="#${newSection.id}"]`,
        )

        if (activeLink) {
          activeLink.classList.add(activeClass)
        }
      }

      currentSection = newSection
    }
  }

  // Add scroll event listener with throttling for better performance
  let ticking = false
  window.addEventListener("scroll", () => {
    if (!ticking) {
      window.requestAnimationFrame(() => {
        updateActiveSection()
        ticking = false
      })
      ticking = true
    }
  })

  // Initial call to set active state on page load
  updateActiveSection()
})
