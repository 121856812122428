import { spline } from "@georgedoescode/spline"
import { createNoise2D } from "simplex-noise"

const POINTS = [
  [
    [0.45, 0.05],
    [0.9, 0.35],
    [0.83, 0.81],
    [0.35, 0.95],
    [0.05, 0.48],
  ],
  [
    [0.45, 0.95],
    [0.9, 0.65],
    [0.83, 0.19],
    [0.35, 0.05],
    [0.05, 0.52],
  ],
  [
    [0.55, 0.05],
    [0.11, 0.27],
    [0.17, 0.77],
    [0.61, 0.95],
    [0.92, 0.54],
  ],
  [
    [0.55, 0.95],
    [0.1, 0.65],
    [0.17, 0.19],
    [0.65, 0.05],
    [0.95, 0.52],
  ],
]

function shuffleArray(array) {
  for (var i = array.length - 1; i >= 0; i--) {
    var j = Math.floor(Math.random() * (i + 1))
    var temp = array[i]
    array[i] = array[j]
    array[j] = temp
  }
}

shuffleArray(POINTS)

document.querySelectorAll("[data-blob]").forEach((svgElement, index) => {
  const path = svgElement.querySelector("path")

  const noise2D = createNoise2D()

  let noiseStep = 0.001

  function noise(x, y) {
    return noise2D(x, y)
  }

  // map a number from 1 range to another
  function map(n, start1, end1, start2, end2) {
    return ((n - start1) / (end1 - start1)) * (end2 - start2) + start2
  }

  const points = []

  let newPoint = (x, y) =>
    points.push({
      x,
      y,
      originX: x,
      originY: y,
      noiseOffsetX: Math.random() * 1000,
      noiseOffsetY: Math.random() * 1000,
    })

  POINTS[index % POINTS.length].forEach(([x, y]) => newPoint(x, y))

  let animate = () => {
    path.setAttribute("d", spline(points, 1, true))

    for (let i = 0; i < points.length; i++) {
      const point = points[i]

      const nX = noise(point.noiseOffsetX, point.noiseOffsetX)
      const nY = noise(point.noiseOffsetY, point.noiseOffsetY)

      let spread = 0.025

      const x = map(nX, -1, 1, point.originX - spread, point.originX + spread)
      const y = map(nY, -1, 1, point.originY - spread, point.originY + spread)

      point.x = x
      point.y = y

      point.noiseOffsetX += noiseStep
      point.noiseOffsetY += noiseStep
    }

    requestAnimationFrame(animate)
  }

  animate()
})
