// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Caml_option from "rescript/lib/es6/caml_option.js";

function elementById(id) {
  return Caml_option.nullable_to_opt(document.getElementById(id));
}

var openButton = Caml_option.nullable_to_opt(document.getElementById("open-mobile-navigation"));

var closeButton = Caml_option.nullable_to_opt(document.getElementById("close-mobile-navigation"));

var mobileNavigation = Caml_option.nullable_to_opt(document.getElementById("mobile-navigation-overlay"));

if (openButton !== undefined && closeButton !== undefined && mobileNavigation !== undefined) {
  var mobileNavigation$1 = Caml_option.valFromOption(mobileNavigation);
  Caml_option.valFromOption(openButton).addEventListener("click", (function (param) {
          mobileNavigation$1.classList.add("mobile-navigation-component__overlay--open");
        }));
  Caml_option.valFromOption(closeButton).addEventListener("click", (function (param) {
          mobileNavigation$1.classList.remove("mobile-navigation-component__overlay--open");
        }));
}

export {
  elementById ,
  openButton ,
  closeButton ,
  mobileNavigation ,
}
/* openButton Not a pure module */
