const filterBar = document.getElementById("posts-filter-bar")

if (filterBar) {
  const typeButtons = filterBar.querySelectorAll(
    ".posts-filter-bar-component__button",
  )

  const typeSelector = filterBar.querySelector(
    ".posts-filter-bar-component__select--post-type",
  )

  const topicSelector = filterBar.querySelector(
    ".posts-filter-bar-component__select--post-topic",
  )

  let type = "all"
  let topic = new URLSearchParams(window.location.search).get("topic") || "all"

  const sync = () => {
    typeButtons.forEach((button) => {
      if (button.dataset.type === type) {
        button.classList.add("posts-filter-bar-component__button--active")
      } else {
        button.classList.remove("posts-filter-bar-component__button--active")
      }
    })

    typeSelector.value = type
    topicSelector.value = topic

    // Set the topic query parameter.
    const url = new URL(window.location)
    url.searchParams.set("topic", topic)
    window.history.replaceState({}, "", url)

    document.querySelectorAll(".posts-detail-component").forEach((post) => {
      if (type === "all" && topic === "all") {
        post.classList.remove("posts-detail-component--hidden")
      } else {
        const matchesType = type === "all" || post.dataset.type === type
        const matchesTopic = topic === "all" || post.dataset.topic === topic

        if (matchesType && matchesTopic) {
          post.classList.remove("posts-detail-component--hidden")
        } else {
          post.classList.add("posts-detail-component--hidden")
        }
      }
    })

    document
      .querySelectorAll(".posts-by-topic-component")
      .forEach((topicComponent) => {
        const postCount = topicComponent.querySelectorAll(
          ".posts-detail-component:not(.posts-detail-component--hidden)",
        ).length

        if (postCount === 0) {
          topicComponent.classList.add("posts-by-topic-component--hidden")
        } else {
          topicComponent.classList.remove("posts-by-topic-component--hidden")
        }
      })
  }

  typeButtons.forEach((button) => {
    const buttonType = button.dataset.type

    button.addEventListener("click", () => {
      type = buttonType

      sync()
    })
  })

  typeSelector.addEventListener("change", (e) => {
    type = e.target.value

    sync()
  })

  topicSelector.addEventListener("change", (e) => {
    topic = e.target.value

    sync()
  })

  sync()
}
